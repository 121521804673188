export default [
  {
    key: 'regionId',
    label: 'field.region',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
  },
  {
    key: 'salespersonId',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
  },
  {
    key: 'workingScheduleId',
    label: 'field.workingSchedule',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'workingSchedule',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'attendanceDate',
    label: 'field.attendanceDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
  {
    key: 'checkinLate',
    label: 'field.checkinLate',
    type: 'price-range-input',
    cast: 'range',
    firstPlacehoder: "field.from",
    secondPlacehoder: "field.to",
    suffix: "time.second",
    suffixLocalize: true,
    firstOperator: '>=',
    secondOperator: '<=',
    md: 12,
    lg: 6,
  },
  {
    key: 'checkoutEarly',
    label: 'field.checkoutEarly',
    type: 'price-range-input',
    cast: 'range',
    firstPlacehoder: "field.from",
    secondPlacehoder: "field.to",
    suffix: "time.second",
    suffixLocalize: true,
    firstOperator: '>=',
    secondOperator: '<=',
    md: 12,
    lg: 6,
  },
]
