export default [
  {
    key: 'regionCode',
    label: 'field.region',
    width: 2000,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 3000,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
  },
  {
    key: 'workingScheduleName',
    label: 'field.workingSchedule',
    localization: true,
    width: 6000,
  },
  {
    key: 'workingShift',
    label: 'field.shift',
    width: 6000,
  },
  {
    key: 'attendanceDate',
    label: 'field.attendanceDate',
  },
  {
    key: 'checkinLate',
    label: 'field.checkinLate',
  },
  {
    key: 'checkoutEarly',
    label: 'field.checkoutEarly',
  },
  {
    key: 'checkinTime',
    label: 'field.checkinTime',
  },
  {
    key: 'checkinImage',
    label: 'field.checkinImage',
    width: 10000,
  },
  {
    key: 'checkinAddress',
    label: 'field.checkinAddress',
    width: 10000,
  },
  {
    key: 'checkinLatitude',
    label: 'field.checkinLatitude',
  },
  {
    key: 'checkinLongitude',
    label: 'field.checkinLongitude',
  },
  {
    key: 'checkoutTime',
    label: 'field.checkoutTime',
  },
  {
    key: 'checkoutImage',
    label: 'field.checkoutImage',
    width: 10000,
  },
  {
    key: 'checkoutAddress',
    label: 'field.checkoutAddress',
    width: 10000,
  },
  {
    key: 'checkoutLatitude',
    label: 'field.checkoutLatitude',
  },
  {
    key: 'checkoutLongitude',
    label: 'field.checkoutLongitude',
  },
  {
    key: 'remark',
    label: 'field.remark',
  },
];
