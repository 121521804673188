export default [
  {
    key: 'checkinDateTime',
    label: 'field.checkinTime',
    type: 'time-picker',
    rules: 'required',
    hideOptional: true,
    cols: 12,
  },
  {
    key: 'checkoutDateTime',
    label: 'field.checkoutTime',
    type: 'time-picker',
    rules: 'required',
    hideOptional: true,
    cols: 12,
  },
  {
    key: 'remark',
    label: 'field.remark',
    type: 'textarea',
    cols: 12,
  },
]
