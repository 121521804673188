<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <export-excel-button
                  :resource="resource"
                  :query="query"
                  :params="params"
                  :loading="loading"
                  :repository="AttendanceRepository"
                  :fields="exportFields"
                  :search-fields="searchFields"
                  :file-name="$t('breadcrumb.attendance')"
                />
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :visible-columns="visibleColumns"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
        >
          <template #cell(checkinLate)="data">
            <b-badge variant="light-danger" pill v-if="data.item.checkinLate">
              {{ toMinutesSeconds(data.item.checkinLate) }}
            </b-badge>
            <span v-else> - </span>
          </template>
          <template #cell(checkoutEarly)="data">
            <b-badge variant="light-danger" pill v-if="data.item.checkoutEarly">
              {{ toMinutesSeconds(data.item.checkoutEarly) }}
            </b-badge>
            <span v-else> - </span>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-b-tooltip.hover
                :title="$t('button.update')"
                :disabled="!$can('update', resource)"
                :variant="!$can('update', resource) ? 'secondary' : 'primary'"
                pill
                size="sm"
                @click="showUpdateForm(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$t('button.log')"
                variant="primary"
                pill
                size="sm"
                @click="showRemark(data.item)"
              >
                <feather-icon icon="MessageCircleIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>

    <update-modal ref="updateModal" @success="getData" />
    <remark-modal ref="remarkModal" />
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BBadge, BImg, BLink } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import ExportExcelFields from "./exportExcelFields";
import SearchInputs from "./searchInput";
import { toMinutesSeconds } from "@/libs/helper";
import purpose from "@/data/purpose";
import NColumnVisibility from "@/components/NColumnVisibility";
import ExportExcelButton from "@/components/ExportExcelButton";
import UpdateModal from "./updateModal/Index.vue";
import RemarkModal from "./remarkModal/Index.vue";

const AttendanceRepository = Repository.get("attendance");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BImg,
    BLink,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NColumnVisibility,
    ExportExcelButton,
    vSelect,
    UpdateModal,
    RemarkModal,
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.attendance;
      },
      set(value) {
        this.$store.commit("column/SET_ATTENDANCE_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        attendanceDate: this.$route.query.attendanceDate || [],
        regionId: this.$route.query.regionId || [],
        salespersonId: this.$route.query.salespersonId || [],
        workingScheduleId: this.$route.query.workingScheduleId || [],
        checkinLate: this.$route.query.checkinLate || [],
        checkoutEarly: this.$route.query.checkoutEarly || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || "DESC",
        attendanceDate: this.$route.query.attendanceDate || [],
        regionId: this.$route.query.regionId || [],
        salespersonId: this.$route.query.salespersonId || [],
        workingScheduleId: this.$route.query.workingScheduleId || [],
        checkinLate: this.$route.query.checkinLate || [],
        checkoutEarly: this.$route.query.checkoutEarly || [],
      },
      purpose,
      toMinutesSeconds,
      AttendanceRepository,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    showRemark(item) {
      this.$refs.remarkModal.show(item);
    },
    showUpdateForm(item) {
      this.$refs.updateModal.show(item);
    },
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.search();
    },
    getData() {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();

      AttendanceRepository.index({
        ...this.query,
        searchFields: this.searchFields,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const exportFields = ExportExcelFields;
    const resource = "attendance";
    const route = "attendance";

    return {
      fields,
      searchFields,
      exportFields,
      resource,
      route,
    };
  },
};
</script>
